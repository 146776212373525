import Animation from "./Animation";
import loadingAnimation from "../animations/loading.json";


import animation1 from "../animations/fve_positive/bat_positive/ac_zero/p->d_x_e_bat_charge.json";
import animation2 from "../animations/fve_positive/bat_negative/ac_zero/p->d_x_e_bat_discharge.json";
import animation3 from "../animations/fve_positive/bat_zero/ac_zero/p->d_x_e_bat_x.json";

import animation4 from "../animations/fve_positive/bat_positive/ac_negative/p->d->e_bat_charge.json";
import animation5 from "../animations/fve_positive/bat_negative/ac_negative/p->d->e_bat_discharge.json";
import animation6 from "../animations/fve_positive/bat_zero/ac_negative/p->d->e_bat_x.json";

import animation7 from "../animations/fve_positive/bat_positive/ac_positive/p->d<-e_bat_charge.json";
import animation8 from "../animations/fve_positive/bat_negative/ac_positive/p->d<-e_bat_discharge.json";
import animation9 from "../animations/fve_positive/bat_zero/ac_positive/p->d<-e_bat_x.json";


import animation10 from "../animations/fve_zero/bat_positive/ac_zero/p_x_d_x_e_bat_charge.json";
import animation11 from "../animations/fve_zero/bat_negative/ac_zero/p_x_d_x_e_bat_discharge.json";
import animation12 from "../animations/fve_zero/bat_zero/ac_zero/p_x_d_x_e_bat_x.json";

import animation13 from "../animations/fve_zero/bat_positive/ac_negative/p_x_d->e_bat_charge.json";
import animation14 from "../animations/fve_zero/bat_negative/ac_negative/p_x_d->e_bat_discharge.json";
import animation15 from "../animations/fve_zero/bat_zero/ac_negative/p_x_d->e_bat_x.json";

import animation16 from "../animations/fve_zero/bat_positive/ac_positive/p_x_d<-e_bat_charge.json";
import animation17 from "../animations/fve_zero/bat_negative/ac_positive/p_x_d<-e_bat_discharge.json";
import animation18 from "../animations/fve_zero/bat_zero/ac_positive/p_x_d<-e_bat_x.json";




import { useEffect, useState } from "react";
import { getAnimationRefreshInterval } from "../repositories/AnimationRepository";

import { getNewestDataForAnimation } from "../repositories/AnimationRepository";
import { AnimationACState, AnimationBATState, AnimationFVEState } from "../enums/AnimationEnums";

const animations_map = {
  [AnimationFVEState.POSITIVE]: {
    [AnimationBATState.POSITIVE]: {
      [AnimationACState.POSITIVE]: animation7,
      [AnimationACState.NEGATIVE]: animation4,
      [AnimationACState.ZERO]: animation1

    },
    [AnimationBATState.NEGATIVE]: {
      [AnimationACState.POSITIVE]: animation8,
      [AnimationACState.NEGATIVE]: animation5,
      [AnimationACState.ZERO]: animation2

    },
    [AnimationBATState.ZERO]: {
      [AnimationACState.POSITIVE]: animation9,
      [AnimationACState.NEGATIVE]: animation6,
      [AnimationACState.ZERO]: animation3

    }
  },
  [AnimationFVEState.ZERO]: {
    [AnimationBATState.POSITIVE]: {
      [AnimationACState.POSITIVE]: animation16,
      [AnimationACState.NEGATIVE]: animation13,
      [AnimationACState.ZERO]: animation10

    },
    [AnimationBATState.NEGATIVE]: {
      [AnimationACState.POSITIVE]: animation17,
      [AnimationACState.NEGATIVE]: animation14,
      [AnimationACState.ZERO]: animation11

    },
    [AnimationBATState.ZERO]: {
      [AnimationACState.POSITIVE]: animation18,
      [AnimationACState.NEGATIVE]: animation15,
      [AnimationACState.ZERO]: animation12

    }
  },
}



function determine_animation(fve, batery_power, acVoltage) {
  let fve_state = AnimationFVEState.fromValue(fve);
  let bat_state = AnimationBATState.fromValue(batery_power);
  let ac_state = AnimationACState.fromValue(acVoltage);

  return animations_map[fve_state][bat_state][ac_state];
  
}

const LiveStatus = () => {
  let [currentAnimation, setCurrentAnimation] = useState(loadingAnimation);
  const [actualPower, setActualPower] = useState(0);
  const [acVoltage, setAcVoltage] = useState(0);
  const [batteryPower, setBatteryPower] = useState(0);
  const [batterySOC, setBatterySOC] = useState(0);
  const [housePower, setHousePower] = useState(0);


  useEffect(() => {
    async function get_determinant_data() {
      console.log("getting newest data");

      const newest_data_pack = await getNewestDataForAnimation();

      const performance = newest_data_pack.performance;
      const supply = newest_data_pack.supply;
      const batterySOC = newest_data_pack.batterySOC;
      const batteryPower = newest_data_pack.batteryPower;
      const acVoltage = newest_data_pack.acVoltage;
      const housePower = newest_data_pack.housePower;

      let animation = determine_animation(performance, batteryPower, acVoltage);

      setCurrentAnimation(animation);

      setActualPower(performance);
      setAcVoltage(acVoltage);
      setBatteryPower(batteryPower);


      setBatterySOC(batterySOC);
      setHousePower(housePower);


    }

    get_determinant_data();

    const interval = setInterval(() => {
      get_determinant_data();
    }, getAnimationRefreshInterval());

    return () => clearInterval(interval);
  }, []);




  return (
  <Animation animationData={currentAnimation}  technicalData={{
    actualPower: actualPower,
    acVoltage: acVoltage,
    batteryPower: batteryPower,
    batterySOC: batterySOC,
    housePower: housePower,
    units : {
      actualPower: "kW",
      acVoltage: "kW",
      batteryPower: "kW",
      batterySOC: "%",
      housePower: "kW"
    },
  }}/>);
  
  

};

export default LiveStatus;
