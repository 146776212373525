import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import "chartjs-adapter-date-fns";
import CrosshairPlugin from "chartjs-plugin-crosshair"; // Import the crosshair plugin
import { fetchDataSchemas } from "../repositories/GraphRepository";
import { getAnimationRefreshInterval } from "../repositories/AnimationRepository";

const defaultUnit = "kW";

ChartJS.register(
  TimeScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  CrosshairPlugin
);

function datetime_to_date(dtime) {
  return dtime.split("T")[0];
}

function get_x_y_pairs(data) {
  // x will be time
  // y will be value
  const pairs = [];
  for (let i = 0; i < data.length; i++) {
    let x = extract_time(data[i].dateTime);
    let y = data[i].value;

    pairs.push({ x, y });
  }

  return pairs;
}

function extract_time(dateTimeString) {
  const date = new Date(dateTimeString);

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
}

function get_info(schema) {
  switch (schema) {
    case "ActualPower":
      return {
        label: "FV napájení",
        color: "255, 140, 0",
        axis: "left-y-axis",
      };
    case "ACVoltage":
      return {
        label: "AC napájení (na síti)",
        color: "50, 205, 50",
        axis: "left-y-axis",
      };
    case "PowerConsumption":
      return {
        label: "Spotřeba energie",
        color: "128, 0, 128",
        axis: "left-y-axis",
      };
    case "SupplyFromMains":
      return {
        label: "Dodávka ze sítě",
        color: "255, 140, 140",
        axis: "left-y-axis",
      };
    case "BatteryPower":
      return {
        label: "Síla baterie",
        color: "0, 255, 255",
        axis: "left-y-axis",
      };
    case "BatterySoc":
      return {
        label: "Baterie SoC",
        color: "173, 216, 230",
        axis: "right-y-axis",
      };
    default:
      return { label: "", color: "", axis: "" };
  }
}

const InformationGraphWidget = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [newDataArrivalTime, setNewDataArrivalTime] = useState(new Date());

  const generateData = (label, color, data, yaxis) => {
    return {
      label,
      yAxisID: yaxis,
      data,
      borderColor: `rgba(${color}, 1)`,
      backgroundColor: `rgba(${color}, 0.3)`,
      fill: true,
      pointRadius: 0,
      pointHoverRadius: 0,
    };
  };

  const [datasets, setDatasets] = useState({
    ActualPower: {}, //FV napájení
    ACVoltage: {}, // AC napájení (na síti)
    PowerConsumption: {}, // Spotřeba energie
    SupplyFromMains: {}, // Dodávka ze sítě
    BatteryPower: {}, // Síla baterie
    BatterySoc: {}, // Baterie SoC
  });


  useEffect(() => {
    const interval = setInterval(() => {
      setNewDataArrivalTime(new Date());
    }, getAnimationRefreshInterval());

    return () => clearInterval(interval);
  }, []);



  // use effect with dependency on startDate
  useEffect(() => {
    const targetDate = datetime_to_date(startDate.toISOString());
    const keys = Object.keys(datasets);

    async function get_actual_power_data(date, schema) {
      // look for data in schemas

      const data = await fetchDataSchemas(date, schema);

      const xy = get_x_y_pairs(data);
      const info = get_info(schema);
      setDatasets((prev) => {
        return {
          ...prev,
          [schema]: generateData(info.label, info.color, xy, info.axis),
        };
      });
    }

    for (let key of keys) {
      get_actual_power_data(targetDate, key);
    }
  }, [startDate, newDataArrivalTime]);

  let datasets_arr = Object.values(datasets);
  datasets_arr = datasets_arr.filter(
    (dataset) => Object.keys(dataset).length > 0
  );

  const data = {
    datasets: datasets_arr,
  };

  console.log(data);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 20,
          padding: 10,
        },
      },
      crosshair: {
        line: {
          color: "grey", // Crosshair color
          width: 3, // Crosshair width
        },
        sync: {
          enabled: false, // Enable trace line syncing with other charts
          group: 1, // chart group
          suppressTooltips: false, // Suppress tooltips when showing a synced tracer
        },
        zoom: {
          enabled: false, // Enable zooming
          zoomboxBackgroundColor: "rgba(66,133,244,0.2)", // Background color of zoom box
          zoomboxBorderColor: "#48F", // Border color of zoom box
          zoomButtonText: "Reset Zoom", // Reset zoom button text
          zoomButtonClass: "reset-zoom", // Reset zoom button class
        },
        callbacks: {
          beforeZoom: function (start, end) {
            // Callback called before zooming
            return true;
          },
          afterZoom: function (start, end) {
            // Callback called after zooming
          },
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (context) {
            const axisLabel = context.dataset.label || "";
            const yaxis = context.dataset.yAxisID;
            const unit = yaxis === "right-y-axis" ? "%" : defaultUnit;

            let value = context.raw.y;
            value = value.toFixed(2);
            return `${axisLabel}: ${value} ${unit}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "hour",
          parser: "HH:mm",
          displayFormats: {
            hour: "H",
          },
          tooltipFormat: "HH:mm",
        },
        title: {
          display: true,
          text: "Hodina",
        },
      },
      "left-y-axis": {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "Kilowatty",
        },
        ticks: {
          callback: (value) => `${value} ${defaultUnit}`,
        },
      },
      "right-y-axis": {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "",
        },
        min: 0,
        max: 100,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: (value) => `${value} %`,
        },
      },
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
    animation: {
      duration: 100,
    },
  };

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const incrementDate = () => {
    setStartDate(
      (prevDate) => new Date(prevDate.getTime() + 24 * 60 * 60 * 1000)
    );
  };

  const decrementDate = () => {
    setStartDate(
      (prevDate) => new Date(prevDate.getTime() - 24 * 60 * 60 * 1000)
    );
  };

  return (
    <div className="information-graph-widget bg-white  flex-1 rounded-xl py-8 px-8 xs:px-2">
      <h1 className="text-2xl font-bold text-center p-4 pb-0">
        Informační grafy
      </h1>
      <div className="w-full flex justify-end align-center xs:justify-center">
        <div className="date-picker-container inline-flex  mr-2 p-5 justify-end w-max">
          <button className="date-nav-button invertX" onClick={decrementDate}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m15 15 6-6m0 0-6-6m6 6H9a6 6 0 0 0 0 12h3"
              />
            </svg>
          </button>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            className="custom-datepicker mx-3"
          />
          <button className="date-nav-button" onClick={incrementDate}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m15 15 6-6m0 0-6-6m6 6H9a6 6 0 0 0 0 12h3"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="graph-container relative h-auto xs:!min-h-0 xs:h-96 xs:w-full">
        <Line data={data} options={options} />{" "}
      </div>
    </div>
  );
};

export default InformationGraphWidget;
